import React from "react";
import styles from "./VerOs.module.css";
import Logo from "../../Assets/logo-2.png";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../../Components/Api";
import jsPDF from "jspdf";

const VerOs = () => {
  const { id } = useParams();
  const [resultados, setResultados] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [valores, setValores] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [gastos, setGastos] = React.useState(0);
  function generatePDF() {
    var doc = new jsPDF("p", "pt", "a3");
    doc.html(document.querySelector("#pdf"), {
      callback: function (pdf) {
        pdf.save("mypdf.pdf");
      },
    });
  }

  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/ordem/${id}`).then((res) => {
        setResultados(res.data[0]);
        setValores(JSON.parse(res.data[0].valores));
        setLoading(false);
        if (
          !res.data[0].total.includes(".") &&
          !res.data[0].total.includes(",")
        ) {
          let formatado = res.data[0].total + ",00";
          setTotal(formatado);
        } else {
          let formatado = res.data[0].total;
          setTotal(formatado);
        }
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  //Gastos
  React.useEffect(() => {
    try {
      Axios.get(`${API_URL}/getgastos/${id}`).then((res) => {
        let soma = 0;
        res.data.forEach((e) => {
          soma = +soma + +e.total;
        });
        setGastos(soma);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <section className={styles.mainContainer}>
      <div>
        <div className={styles.buttons}>
          <Link to={`/editos/${id}`}>
            <button className="btn">
              <i className="fa fa-pencil"></i>Editar
            </button>
          </Link>

          <button className="btn" onClick={generatePDF}>
            <i className="fa fa-file-pdf-o"></i> PDF
          </button>
          <Link to={`/deleteos/${id}`}>
            <button className="btn-excluir">
              <i className="fa fa-trash"></i>Excluir
            </button>
          </Link>
          <Link to={`/gastos/${id}`}>
            <button className="btn-excluir">
              <i className="fa fa-usd"></i>Gastos
            </button>
          </Link>
          <h1>
            <i className="fa fa-arrow-circle-up"></i>R${resultados.total}
          </h1>
          <h1>
            <i className="fa fa-arrow-circle-down"></i>R${gastos}
          </h1>
        </div>
        <div id="pdf" className={`${styles.osDetails} ${styles.pdf}`}>
          <div>
            <div className={styles.top}>
              <div className={styles.introTop}>
                <img src={Logo} alt="" />
                <h1>
                  Assitencia técnica especializada, reparo de celulares,
                  computadores, tablets, notebooks e muito mais.
                </h1>
              </div>
              <div className={styles.introBottom}>
                <div>
                  <p>CNPJ: 47.399.732/0001-60</p>
                  <h1>DSN Soluções</h1>
                  <p>Rua Capitão Francisco Inácio, 136 - Box 3</p>
                  <p>Centro - Poá SP - CEP 08551-150</p>
                </div>
                <div>
                  <p></p>
                  <h1>Fone: (11) 9 6332-5827</h1>
                  <p>E-mail: contato@dsnsolucoes.com.br</p>
                  <p>www.dsnsolucoes.com.br</p>
                </div>
              </div>
            </div>
            <div className={styles.osNumber}>
              <div>
                <h1>ORDEM DE SERVIÇO</h1>
                <span>Nª {id}</span>
              </div>
              <div>
                <h1>Data: {resultados.dia}</h1>
              </div>
            </div>
            <fieldset className={`${styles.fildset} ${styles.clientInfo}`}>
              <legend>Cliente</legend>
              <div>
                <p>
                  <b>Nome:</b> {resultados.nome}
                </p>
                <p>
                  <b>CPF:</b> {resultados.cpf}
                </p>
                <p>
                  <b>Email:</b> {resultados.email}
                </p>
              </div>
              <div>
                <p>
                  <b>Endereço:</b> {resultados.endereco}, {resultados.numero} -{" "}
                  {resultados.cidade}
                </p>
              </div>
            </fieldset>
            <fieldset className={styles.fildset}>
              <legend>Item a ser reparado</legend>
              <p>{resultados.titulo}</p>
            </fieldset>
            <fieldset className={styles.fildset}>
              <legend>Diagnóstico do defeito</legend>
              <p>{resultados.diag}</p>
            </fieldset>
            <fieldset className={styles.fildset}>
              <legend>Ação a ser executada</legend>
              <p>{resultados.acao}</p>
            </fieldset>
            <fieldset className={styles.fildset}>
              <legend>Observação</legend>
              <p>{resultados.observacao}</p>
            </fieldset>
            <div className={styles.valores}>
              <ul className={styles.header}>
                <li>QND</li>
                <li>Descrição do serviço</li>
                <li>Valor</li>
              </ul>
              {valores &&
                valores.map((item) => (
                  <ul key={item.nome} className={styles.items}>
                    <li>1</li>
                    <li>{item.nome}</li>
                    <li>R${item.preco}</li>
                  </ul>
                ))}
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.alerta}>
              <ol>
                <li>
                  Garantimos esse produto pelo periódo de{" "}
                  <b>{resultados.garantia} dias</b>.
                </li>
                <li>
                  A garantia dos produtos é válida <b>SOMENTE</b> contra
                  defeitos de fabricação.
                </li>
                <li>
                  A garantia não cobre defeitos ocasionados por mau uso, má
                  instalação, quedas ou danos causados por forças naturais.
                </li>
                <li>
                  Os produtos defeituosos deverão ser devolvidos em embalagens
                  originais, acompanhados de todos os acessórios.
                </li>
                <li>
                  Todos nossos produtos são testados antecipadamente para evitar
                  maiores problemas.
                </li>
              </ol>
              <p>
                A não retirada do aparelho em <b>90 dias</b> resultará no
                acréscimo de 30% do valor do serviço, afim de cobrir as despesas
                geradas.
              </p>
              <p>
                Concordo com os termos e condições descritas na ordem de Serviço
                acima.
              </p>
            </div>
            <div className={styles.total}>
              <p className={styles.pLine}>Assinatura Cliente</p>
              <p className={styles.pLine}>Assinatura Técnico</p>
              <p>
                Total <span>R${total}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerOs;

import React from "react";
import logo from "../../Assets/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./SideNav.css";
import { useAuth } from "../../contexts/AuthContext";

const SideNav = ({ sidebar, sidebarOpen, closeSideBar, setSidebar }) => {
  const { pathname } = useLocation();
  const { currentUser, logout } = useAuth();
  const history = useNavigate();

  React.useEffect(() => {
    setSidebar(false);
  }, [pathname]);

  async function handleLogout(e) {
    e.preventDefault();
    try {
      await logout();
      history("/login");
    } catch {
      console.log("Erro ao sair.");
    }
  }

  return (
    <div className={sidebar ? "sidebar-responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <div>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <i
          onClick={() => closeSideBar()}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        ></i>
      </div>

      <div className="sidebar__menu">
        <NavLink to="/" className="sidebar__link">
          <i className="fa fa-minus-square"></i>
          Home
        </NavLink>
        <h2>Admin</h2>
        <NavLink to="/financeiro-beta" className="sidebar__link">
          <i className="fa fa-tachometer"></i>
          Financeiro
        </NavLink>
        <NavLink to="/clientes" className="sidebar__link">
          <i className="fa fa-users"></i>
          Clientes
        </NavLink>
        <NavLink to="/ordens" className="sidebar__link">
          <i className="fa fa-database"></i>
          Ordens Geradas
        </NavLink>
        <NavLink to="/novocliente" className="sidebar__link">
          <i className="fa fa-plus"></i>
          Adicionar Cliente
        </NavLink>
        <NavLink to="/novaos" className="sidebar__link">
          <i className="fa fa-bookmark"></i>
          Gerar Nova O.S.
        </NavLink>
        <div className="sidebar__logout">
          <button onClick={handleLogout}>
            <i className="fa fa-sign-out"></i>
            Sair
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

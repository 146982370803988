import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../Components/Api";

const Delete = () => {
  const { id } = useParams();
  const history = useNavigate();
  React.useState(() => {
    try {
      Axios.delete(`${API_URL}/delete/${id}`).then(() => {
        history("/clientes");
      });
    } catch (err) {
      console.log(err);
      history("/clientes");
    }
  }, []);
  return <div></div>;
};

export default Delete;

import React, { useRef } from "react";
import { useParams, Link } from "react-router-dom";
import styles from "./Gastos.module.css";
import Axios from "axios";
import { API_URL } from "../../Components/Api";
import Loader from "../../Components/Helper/Loader";
import Charts from "../../Components/charts/Charts";

const Gastos = () => {
  const { id } = useParams();
  const [data, setData] = React.useState("");
  const [dataOs, setDataOs] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [totalEn, setTotalEn] = React.useState(0);
  const [totalLu, setTotalLu] = React.useState(0);
  const nomeItem = useRef();
  const valorItem = useRef();

  //Post Gastos
  function handleSubmit(e) {
    e.preventDefault();
    if (nomeItem.current.value == "") return;
    if (valorItem.current.value == "") return;
    try {
      setLoading(true);
      Axios.post(`${API_URL}/postgastos`, {
        item: nomeItem.current.value,
        total: valorItem.current.value,
        ordem: id,
      }).then(() => {
        setLoading(false);
        nomeItem.current.value = "";
        valorItem.current.value = "";
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  //Data Gastos
  React.useEffect(() => {
    try {
      Axios.get(`${API_URL}/getgastos/${id}`).then((res) => {
        setData(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, [loading]);

  //Data Os
  React.useEffect(() => {
    try {
      Axios.get(`${API_URL}/ordem/${id}`).then((res) => {
        setDataOs(res.data[0]);
        setTotalEn(+res.data[0].total);
      });
    } catch (err) {
      console.log(err);
    }
    localStorage.setItem("redirect", `/gastos/${id}`);
  }, []);

  //Somar Saidas
  React.useEffect(() => {
    if (data) {
      let somaTotal = 0;
      data.forEach((e) => {
        somaTotal = +somaTotal + +e.total;
      });
      setTotal(somaTotal);
    }
  }, [data, loading]);

  React.useEffect(() => {
    if (data) {
      setTotalLu(+totalEn - +total);
    }
  }, [totalEn, total]);

  return (
    <section className={styles.mainContainer}>
      <div className={styles.greetings}>
        <h1>Gastos da Ordem de serviço.</h1>
        <p className="text-primary-p">Ordem numero #{id}</p>
      </div>
      <div className={styles.topCards}>
        <div>
          <i className="fa fa-2x fa-arrow-circle-up"></i>
          <span className="font-bold text-title">R${dataOs.total}</span>
          <h2>Entrada</h2>
        </div>
        <div>
          <i className="fa fa-2x fa-arrow-circle-down"></i>
          <span className="font-bold text-title">R${total}</span>
          <h2>Saída</h2>
        </div>
        <div>
          <i className="fa fa-2x fa-usd"></i>
          <span className="font-bold text-title">R${totalLu}</span>
          <h2>Lucro</h2>
        </div>
      </div>

      <div className={styles.gastosContainer}>
        <div className={styles.gastos}>
          <h1 className={`text-lightblue`}>Cadastrar saídas da O.S.</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="">Dados do item</label>
            <div>
              <input
                id="nomeItem"
                type="text"
                ref={nomeItem}
                placeholder="Item"
              />
              <input
                id="valorItem"
                type="number"
                ref={valorItem}
                placeholder="Valor"
                step="0.01"
              />
              <button
                className="btn"
                style={{ padding: "8px 10px" }}
                onClick={console.log("oi")}
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            {loading && <p>Carregando...</p>}
          </form>
          <div className={styles.displayItems}>
            <ul>
              {loading && <Loader />}
              {data &&
                data.map((item) => (
                  <li key={item.id}>
                    {item.item}{" "}
                    <span>
                      R${item.total}{" "}
                      <Link to={`/deletegastos/${item.id}`}>
                        <button className="btn-excluir">
                          <i className="fa fa-trash"></i>
                        </button>
                      </Link>
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className={styles.chart}>
          <h1 className={`text-lightblue`}>Resumo da ordem.</h1>
          <p>Contole de gastos.</p>
          {data && <Charts lucro={totalLu} saida={total} />}
        </div>
      </div>
    </section>
  );
};

export default Gastos;

import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  const estilos = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f3f4f6",
    gridArea: "main",
    flexDirection: "column",
    gap: "1em",
  };

  return (
    <section style={estilos}>
      <h1 className="text-lightblue" style={{ fontSize: "4em" }}>
        Error 404
      </h1>
      <p style={{ fontSize: "1.8em", color: "#999999" }}>
        Página não encontrada.
      </p>
      <Link to="/">
        <button
          className="btn"
          style={{ fontWeight: "bold", padding: "10px 30px" }}
        >
          Ir para Home
        </button>
      </Link>
    </section>
  );
};

export default Error404;

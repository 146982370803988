import React from "react";

const Calc = ({ orderId, orderType, orderTotal, calcInfo }) => {
  const [saida, setSaida] = React.useState("");
  const [lucro, setLucro] = React.useState("");

  React.useEffect(() => {
    if (orderType.toLowerCase() === "saida") {
      setSaida(orderTotal);
      setLucro(0);
    } else {
      if (calcInfo) {
        const filtro = calcInfo.filter((item) => {
          return item.ordem == orderId;
        });
        if (!filtro[0]) {
          setSaida(0);
          setLucro(orderTotal);
        } else {
          if (filtro[0].total == null) {
            setSaida(0);
          } else {
            const saida = filtro[0].total;
            setSaida(saida);
            setLucro(+orderTotal - +saida);
          }
        }
      }
    }
  }, []);

  return (
    <>
      <li>R${saida}</li>
      <li>R${lucro ? lucro : "0"}</li>
    </>
  );
};

export default Calc;

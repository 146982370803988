import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyAJ_OKC5Ocj1_KhHwWUDVx3BP9XtCVkeJo",
  authDomain: "admdsn-fdf4b.firebaseapp.com",
  projectId: "admdsn-fdf4b",
  storageBucket: "admdsn-fdf4b.appspot.com",
  messagingSenderId: "817714717847",
  appId: "1:817714717847:web:5d8cfb23e53879831875d2",
});

export const auth = app.auth();
export default app;

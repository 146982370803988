import React from "react";
import styles from "./EditarCliente.module.css";
import InputMask from "react-input-mask";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../../Components/Api";

const EditarCliente = () => {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [clienteData, setClienteData] = React.useState("");
  const history = useNavigate();
  const [cpf, setCpf] = React.useState(true);
  const [nomeS, setNomeS] = React.useState("");
  const { id } = useParams();
  function handleCliente() {
    setCpf(!cpf);
  }
  const nome = React.useRef();
  const sobrenome = React.useRef();
  const [usercpf, setUsercpf] = React.useState("");
  const email = React.useRef();
  const [celular, setCelular] = React.useState("");
  const [celularop, setCelularop] = React.useState("");
  const endereco = React.useRef();
  const numero = React.useRef();
  const complemento = React.useRef();
  const cidade = React.useRef();
  const [cep, setCep] = React.useState("");
  const estado = React.useRef();

  React.useEffect(() => {
    try {
      Axios.get(`${API_URL}/cliente/${id}`).then((res) => {
        setClienteData(res.data[0]);
        if (res.data[0].cpf.length <= 14) {
          setCpf(true);
        } else {
          setCpf(false);
        }
        setNomeS(res.data[0].nome.split(" "));
        setUsercpf(res.data[0].cpf);
        setCelular(res.data[0].celular);
        setCelularop(res.data[0].celularop);
        setCep(res.data[0].cep);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  function updateUser(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (cpf) {
        var nomeClient = `${nome.current.value} ${sobrenome.current.value}`;
        var cpforcnpj = usercpf;
      } else {
        var nomeClient = nome.current.value;
        var cpforcnpj = usercpf;
      }
      Axios.put(`${API_URL}/clientup/${id}`, {
        nome: nomeClient,
        cpf: cpforcnpj,
        email: email.current.value,
        celular: celular,
        celularop: celularop,
        endereco: endereco.current.value,
        numero: numero.current.value,
        complemento: complemento.current.value,
        cidade: cidade.current.value,
        cep: cep,
        estado: estado.current.value,
      }).then((res) => {
        const resposta = Number(
          JSON.parse(JSON.stringify(res.headers).replace("-", "")).contentlength
        );
        if (resposta === 0) {
          setErrorMsg("Falha ao editar cliente, tente novamente mais tarde.");
          setLoading(false);
        } else {
          setLoading(false);
          history("/clientes");
        }
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg("Falha ao editar cliente, tente novamente mais tarde.");
    }
  }

  return (
    <div className={`${styles.mainContainer}`}>
      <h1>Editar Cliente</h1>
      <p>Por gentileza efetuar alterações abaixo.</p>
      <section className={styles.mainSection}>
        <div>
          <div className={styles.tipoCliente}>
            <button
              onClick={handleCliente}
              className={`${cpf ? styles.activated : ""}`}
            >
              <i className="fa fa-user" aria-hidden="true"></i>
              CPF
            </button>
            <button
              onClick={handleCliente}
              className={`${cpf ? "" : styles.activated}`}
            >
              <i className="fa fa-university" aria-hidden="true"></i>
              CNPJ
            </button>
          </div>
          {errorMsg && <div className="errorMsg">{errorMsg}</div>}
          <div className={styles.form}>
            <form action="" onSubmit={updateUser}>
              <h1 className={styles.title}>
                <i className="fa fa-address-book" aria-hidden="true"></i> Dados
                Principais
              </h1>
              {cpf ? (
                <div>
                  <input
                    type="text"
                    placeholder="Nome"
                    required
                    ref={nome}
                    defaultValue={nomeS[0]}
                  />
                  <input
                    type="text"
                    placeholder="Sobrenome"
                    required
                    ref={sobrenome}
                    defaultValue={nomeS[1]}
                  />
                </div>
              ) : (
                <div className={styles.one}>
                  <input
                    type="text"
                    placeholder="Razão social"
                    required
                    ref={nome}
                    defaultValue={clienteData.nome}
                  />
                </div>
              )}
              {cpf ? (
                <InputMask
                  mask="999.999.999-99"
                  placeholder="CPF"
                  value={usercpf}
                  onChange={(e) => setUsercpf(e.target.value)}
                />
              ) : (
                <InputMask
                  mask="99.999.999/9999-99"
                  placeholder="CNPJ"
                  value={usercpf}
                  onChange={(e) => setUsercpf(e.target.value)}
                />
              )}
              <div>
                <InputMask
                  mask="(99) 9 9999-9999"
                  placeholder="Celular"
                  required
                  value={celular}
                  onChange={(e) => setCelular(e.target.value)}
                />
                <InputMask
                  mask="(99) 9999-9999"
                  placeholder="Telefone - Opcional"
                  value={celularop}
                  onChange={(e) => setCelularop(e.target.value)}
                />
              </div>
              <div className={styles.one}>
                <input
                  type="text"
                  placeholder="Email ex: admin@admin.com.br"
                  required
                  defaultValue={clienteData.email}
                  ref={email}
                />
              </div>
              <h1 className={styles.title}>
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                Endereço
              </h1>
              <div>
                <input
                  type="text"
                  placeholder="Endereço ex: Rua Avenida Capitão Francisco Ignácio"
                  ref={endereco}
                  defaultValue={clienteData.endereco}
                />
                <input
                  type="number"
                  placeholder="Numero"
                  ref={numero}
                  defaultValue={clienteData.numero}
                />
                <input
                  type="text"
                  placeholder="Complemento"
                  ref={complemento}
                  defaultValue={clienteData.complemento}
                />
                <input
                  type="text"
                  placeholder="Cidade"
                  ref={cidade}
                  defaultValue={clienteData.cidade}
                />
                <input
                  type="text"
                  placeholder="Estado"
                  ref={estado}
                  defaultValue={clienteData.estado}
                />
                <InputMask
                  mask="99999-999"
                  placeholder="Cep"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                />
              </div>
              <div className={styles.buttonDiv}>
                <Link to="/clientes">
                  <button className={styles.cancel}>Cancelar</button>
                </Link>
                <Link to={`/delete/${id}`}>
                  <button className="btn-excluir">
                    <i className="fa fa-trash"></i>
                  </button>
                </Link>
                <button className={styles.next}>Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditarCliente;

import React from "react";
import styles from "./Login.module.css";
import logo from "../Assets/logo.png";
import logo2 from "../Assets/logo-2.png";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { login } = useAuth();
  const history = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("oi");
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history("/");
    } catch {
      setError("Erro ao efetuar login.");
    }
    setLoading(false);
  }

  return (
    <section className={styles.loginPage}>
      <div className={styles.sideLogin}>
        <div className={styles.fixColor}></div>
      </div>
      <div className={styles.formLogin}>
        <img src={logo2} alt="" />
        <form onSubmit={handleSubmit}>
          {error && <div className="errorMsg">{error}</div>}
          <label htmlFor="">E-mail</label>
          <input type="email" ref={emailRef} required />
          <label htmlFor="">Senha</label>
          <input type="password" ref={passwordRef} required />
          {loading ? (
            <button className="btn">Logando...</button>
          ) : (
            <button onClick={handleSubmit} className="btn">
              Logar
            </button>
          )}
        </form>
      </div>
    </section>
  );
};

export default Login;

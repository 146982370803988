import React from "react";
import { useParams, Link, useNavigate, redirect } from "react-router-dom";
import styles from "./Gerar.module.css";
import Axios from "axios";
import { API_URL } from "../../Components/Api";

const Gerar = () => {
  const { id } = useParams();
  const [statusOrdem, setStatusOrdem] = React.useState("Em progresso");
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [garantia, setGarantia] = React.useState(30);
  const [valores, setValores] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [cliente, setCliente] = React.useState(0);
  const history = useNavigate();

  //Refs
  const nomeItem = React.useRef();
  const valorItem = React.useRef();
  const diag = React.useRef();
  const acao = React.useRef();
  const observacao = React.useRef();
  const titulo = React.useRef();

  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/cliente/${id}`).then((res) => {
        setCliente(res.data[0]);
      });
      setLoading(false);
    } catch (err) {
      setErrorMsg("Falha ao cadastrar ordem de serviço.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  function addValores(e) {
    var formatado;
    e.preventDefault();
    if (nomeItem.current.value === "") return;
    if (valorItem.current.value === "") return;
    if (
      !valorItem.current.value.includes(",") &&
      !valorItem.current.value.includes(".")
    ) {
      formatado = valorItem.current.value + ".00";
    } else {
      formatado = valorItem.current.value.replace(",", ".");
    }
    const newValor = {
      nome: nomeItem.current.value.trim(),
      preco: formatado,
    };
    setValores((prev) => {
      return [...prev, newValor];
    });

    nomeItem.current.value = "";
    valorItem.current.value = "";
    setTotal(+total + +formatado);
  }

  function redirect() {
    try {
      Axios.get(`${API_URL}/setredirect`).then((res) => {
        console.log(res.data);
        history(`/veros/${res.data[0].id}`);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (total == 0) return setErrorMsg("Nenhum valor foi adicionado.");

    const endereco = `${cliente.endereco}, ${cliente.numero} - ${cliente.cidade}`;
    try {
      setLoading(true);
      Axios.post(`${API_URL}/postordem`, {
        titulo: titulo.current.value,
        diag: diag.current.value,
        acao: acao.current.value,
        observacao: observacao.current.value,
        garantia: garantia,
        valores: JSON.stringify(valores),
        total: total,
        statusOrdem: statusOrdem,
        nome: cliente.nome,
        cpf: cliente.cpf,
        email: cliente.email,
        endereco: cliente.endereco,
        numero: cliente.numero,
        cidade: cliente.cidade,
      }).then((res) => {
        console.log(res);
        redirect();
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function handleDelete(e) {
    console.log(e.target.innerText.toLowerCase().trim());
    const selecionado = e.target.innerText.toLowerCase().trim();
    const novoValores = valores.filter((item) => {
      return item.nome.toLowerCase().trim() !== selecionado;
    });
    setValores(novoValores);

    let totalContar = 0;
    novoValores.forEach((e) => {
      console.log(e.preco);
      totalContar = +totalContar + +e.preco;
    });
    console.log(novoValores);
    setTotal(totalContar);
  }

  return (
    <section className={styles.mainContainer}>
      <div className={styles.intro}>
        <h1 className="text-lightblue">Gerando nova O.S.</h1>
        <p>Preencha os dados abaixo.</p>
      </div>
      {errorMsg && (
        <div className="errorMsg animeLeft" style={{ margin: "1em 0 -1em 0" }}>
          {errorMsg}
        </div>
      )}

      <div className={styles.ordemContainer}>
        <div className={styles.ordemForm}>
          <h1 className="text-lightblue">Dados da Ordem de Seviço</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="titulo">Titulo/Tipo de Ordem</label>
            <input
              type="text"
              ref={titulo}
              id="titulo"
              name="titulo"
              required
            />
            <label htmlFor="diag">Diagnóstico do Defeito</label>
            <textarea
              name="diag"
              id="diag"
              rows="5"
              ref={diag}
              required
            ></textarea>
            <label htmlFor="nomeItem">Adicionar Valores</label>
            {valores && (
              <div>
                {valores.map((item) => (
                  <ul className={styles.listItems} key={item.nome}>
                    <li onClick={handleDelete}>{item.nome}</li>
                    <li>R${item.preco}</li>
                  </ul>
                ))}
              </div>
            )}

            <div className={styles.addValores}>
              <input
                type="nomeItem"
                ref={nomeItem}
                name="nomeItem"
                id="nomeItem"
                placeholder="Nome do item"
              />
              <input
                type="number"
                ref={valorItem}
                name="valorItem"
                id="valorItem"
                placeholder="Valor"
              />
              <button type="button" className="btn" onClick={addValores}>
                +
              </button>
            </div>
            <label htmlFor="acao">Ação a ser executada</label>
            <textarea
              name="acao"
              id="acao"
              rows="5"
              ref={acao}
              required
            ></textarea>
            <label htmlFor="obs">Observação</label>
            <textarea name="obs" id="obs" rows="5" ref={observacao}></textarea>
            <div className={styles.total}>
              <p>Total: {total ? `R$${total}` : "0,00"}</p>
            </div>
            <div className={styles.total}>
              {loading ? (
                <button className={styles.next} disabled={loading}>
                  Cadastrando...
                </button>
              ) : (
                <button className={styles.next}>Cadastrar</button>
              )}

              <Link to="/ordens">
                <button className={styles.cancel}>Cancelar</button>
              </Link>
            </div>
          </form>
        </div>
        <div className={styles.ordemSide}>
          <h1 className="text-lightblue">Status após criação.</h1>
          <select
            name="ordemStatus"
            id="ordemStatus"
            onChange={(e) => setStatusOrdem(e.target.value)}
          >
            <option value="Pendente">Pendente</option>
            <option value="Concluida">Concluida</option>
            <option value="Aguardando Peças">Aguardando Peças</option>
            <option value="Cancelada">Cancelada</option>
          </select>
          <p>Será utilizado para que o cliente acompanhe pelo site.</p>
          <h1 className="text-lightblue" style={{ marginTop: "1em" }}>
            Garantia.
          </h1>

          <select
            name="garantia"
            id="garantia"
            onChange={(e) => setGarantia(e.target.value)}
          >
            <option value="30">30 dias</option>
            <option value="60">60 dias</option>
            <option value="90">90 dias</option>
          </select>
        </div>
      </div>
    </section>
  );
};

export default Gerar;

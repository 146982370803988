import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

const NavBar = ({ openSideBar, sidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav__icon" onClick={() => openSideBar()}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>

      <div className="navbar__left">
        <NavLink to="/ordens">Ordens</NavLink>
        <NavLink to="/clientes">Clientes</NavLink>
      </div>

      <div className="navbar__right">
        <NavLink to="/clientes">
          <i className="fa fa-search"></i>
        </NavLink>
      </div>
    </nav>
  );
};

export default NavBar;

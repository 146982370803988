import Axios from "axios";
import React from "react";
import { Route, Routes, NavLink, Link } from "react-router-dom";
import styles from "./Financeiro.module.css";
import { API_URL } from "../../Components/Api";
import Loader from "../../Components/Helper/Loader";
import NovoCliente from "../NovoCliente/NovoCliente";
import { useRef } from "react";

const Financeiro = () => {
  const d = new Date();
  const mes = d.getMonth() + 1;
  const ano = d.getFullYear();
  const [dataIni, setDataIni] = React.useState(`${ano}-${mes}-01`);
  const [dataFim, setDataFim] = React.useState(`${ano}-${mes}-30`);
  const [dataPreju, setDataPreju] = React.useState("");
  const [dataSelect, setDataSelect] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState("");
  const [prejuizo, setPrejuizo] = React.useState(false);
  const [prejuMsg, setPrejuMsg] = React.useState("");
  const [resumoFilter, setResumoFilter] = React.useState("");
  const [dif, setDif] = React.useState("");
  const [delGasto, setDelGasto] = React.useState(false);
  const [dataGastos, setDataGastos] = React.useState("");
  const [title, setTitle] = React.useState("");

  const valorItemPreju = useRef();

  const [dataEntrada, setDataEntrada] = React.useState("");
  const [titleEntrada, setTitleEntrada] = React.useState("");
  const [entradaMsg, setEntradaMsg] = React.useState("");
  const [dataEntradas, setDataEntradas] = React.useState("");
  const [delEntrada, setDelEntrada] = React.useState(false);
  const valorItemEntrada = useRef();

  //Post Entrada
  function handleEntrada(e) {
    e.preventDefault();
    try {
      setEntradaMsg("");
      setLoading(true);
      Axios.post(`${API_URL}/postentradafinan`, {
        titulo: "Sistema",
        nome: titleEntrada,
        cpf: "000.111.222-33",
        email: "sistema@sistema.com",
        acao: "Sistema",
        diag: "Sistema",
        observacao: "Sistema",
        garantia: "0",
        valores: "Sistema",
        total: valorItemEntrada.current.value,
        statusOrdem: "Concluida",
        endereco: "Sistema",
        numero: "0",
        cidade: "Sistema",
        dia: `${dataEntrada} 23:59:59`,
      }).then(() => {
        setLoading(false);
        setTitleEntrada("");
        valorItemEntrada.current.value = "";
        setDataEntrada("");
        setEntradaMsg("Cadastrado entrada ao banco de dados.");
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setEntradaMsg("Erro ao cadastrar entrada no banco de dados.");
    } finally {
      setEntradaMsg("");
    }
  }

  //QueryAllData
  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/getfinanceiro/${dataIni}/${dataFim}`).then(
        (res) => {
          setDataSelect(res.data);
          setFilteredItems(res.data);
          setLoading(false);
        }
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
    localStorage.setItem("redirect", "/financeiro");
  }, [dataIni, dataFim, prejuMsg, dataPreju, title, dataEntrada]);

  // Resumo Filtrado
  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/financeirores/${dataIni}/${dataFim}`).then(
        (res) => {
          setResumoFilter(res.data[0]);
          setLoading(false);
          setDif(res.data[0].totalGanho + res.data[0].totalGastos);
        }
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [dataIni, dataFim, prejuMsg, dataPreju, title, dataEntrada]);

  //Search by Date
  function handleSubmit(e) {
    e.preventDefault();
    try {
      console.log(dataIni, dataFim);
      setLoading(true);
      Axios.get(`${API_URL}/getfinanceiro/${dataIni}/${dataFim}`).then(
        (res) => {
          setDataSelect(res.data);
          setFilteredItems(res.data);
          setLoading(false);
        }
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  //Filter
  React.useEffect(() => {
    let novaArray = [];
    if (filter === "") {
      setFilteredItems(dataSelect);
    } else if (dataSelect) {
      dataSelect.filter((item) => {
        if (item.tipo.includes(filter)) {
          novaArray.push(item);
        }
        setFilteredItems(novaArray);
      });
    }
  }, [filter]);

  //Post Prejuizo
  function handlePreju(e) {
    e.preventDefault();
    try {
      setLoading(true);
      Axios.post(`${API_URL}/postprejuizo`, {
        item: title,
        total: valorItemPreju.current.value,
        dia: `${dataPreju} 23:59:59`,
        ordem: 0,
      }).then(() => {
        setLoading(false);
        setTitle("");
        valorItemPreju.current.value = "";
        setDataPreju("");
        setPrejuMsg("Cadastrado prejuízo ao banco de dados.");
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setPrejuMsg("Erro ao cadastrar prejuízo no banco de dados.");
    } finally {
      setPrejuMsg("");
    }
  }

  //Get Ult Gastos
  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/getultgastos/${dataIni}/${dataFim}`).then((res) => {
        setDataGastos(res.data);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [dataIni, dataFim, delGasto]);

  //Get Ult Entradas
  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/getultentradas/${dataIni}/${dataFim}`).then(
        (res) => {
          setDataEntradas(res.data);
          setLoading(false);
          console.log(res.data);
        }
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [dataIni, dataFim, delEntrada, dataEntrada]);

  return (
    <section className={styles.mainContainer}>
      <div className={styles.greetings}>
        <h1>Financeiro</h1>
        <p className="text-primary-p">Status da base atual.</p>
      </div>

      <div className={styles.topCards}>
        <div>
          <i className="fa fa-2x fa-money"></i>
          <p className="text-primary-p">Entradas</p>
          <span className="font-bold text-title">
            R${resumoFilter ? resumoFilter.totalGanho : "Carregando..."}
          </span>
        </div>
        <div>
          <i className="fa fa-2x fa-arrow-circle-down"></i>
          <p className="text-primary-p">Saidas</p>
          <span className="font-bold text-title">
            R${resumoFilter ? resumoFilter.totalGastos : "Carregando..."}
          </span>
        </div>
        <div>
          <i className="fa fa-2x fa-usd"></i>
          <p className="text-primary-p">Lucro</p>
          <span className="font-bold text-title">
            R${resumoFilter ? dif : "Carregando..."}
          </span>
        </div>
      </div>

      <div className={styles.searchInterval}>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="iniDate">Data Inicial</label>
            <input
              type="date"
              name="iniDate"
              id="iniDate"
              onChange={(e) => setDataIni(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="">Data Final</label>
            <input
              type="date"
              name="fimDate"
              id="fimDate"
              onChange={(e) => setDataFim(e.target.value)}
            />
          </div>
          <div>
            <button className="btn">Filtrar</button>
          </div>
        </form>
      </div>

      <div className={`${styles.dataPrejuizo} ${prejuizo ? styles.show : ""}`}>
        <div className={styles.prejuizoTitle}>
          <h1 className="text-lightblue">Adicionar Prejuizo</h1>
          <button onClick={(e) => setPrejuizo(!prejuizo)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <form onSubmit={handlePreju} className={styles.prejuForm}>
          <div>
            <label htmlFor="nomeItem">Titulo Prejuizo</label>
            <input
              type="text"
              id="nomeItem"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="valorItem">Valor</label>
            <input
              type="number"
              step="0.01"
              id="valorItem"
              ref={valorItemPreju}
              required
            />
          </div>
          <div>
            <label htmlFor="dataItem">Data</label>
            <input
              type="date"
              id="dataItem"
              value={dataPreju}
              onChange={(e) => setDataPreju(e.target.value)}
              required
            />
          </div>
          <div>
            <button className="btn">Adicionar</button>
          </div>
        </form>
        {prejuMsg && <div className="alertMsg">{prejuMsg}</div>}
        {delGasto ? (
          ""
        ) : (
          <button
            onClick={(e) => setDelGasto(!delGasto)}
            className="btn-excluir"
          >
            Apagar Gasto
          </button>
        )}
        {delGasto && (
          <section className={styles.delGastos}>
            <p>Selecione o item a ser excluído</p>
            <div>
              <ul className={styles.itemHeader} style={{ margin: "0" }}>
                <li>ID/OS</li>
                <li>Nome - Item</li>
                <li>Valor</li>
                <li>Data</li>
                <li></li>
              </ul>
              {dataGastos &&
                dataGastos.map((item) => (
                  <ul className={styles.itemList} key={item.id}>
                    <li>{item.id}</li>
                    <li>{item.item}</li>
                    <li>R${item.total}</li>
                    <li>{item.dia}</li>
                    <li>
                      <Link to={`/deletegastos/${item.id}`}>
                        <button className="btn-excluir">
                          <i className="fa fa-trash"></i>
                        </button>
                      </Link>
                    </li>
                  </ul>
                ))}
            </div>
            <div>
              <button className="btn" onClick={(e) => setDelGasto(!delGasto)}>
                Ocultar
              </button>
            </div>
          </section>
        )}

        <div className={styles.prejuizoTitle} style={{ margin: "1em 0" }}>
          <h1 className="text-lightblue">Adicionar Entrada</h1>
        </div>
        <form onSubmit={handleEntrada} className={styles.prejuForm}>
          <div>
            <label htmlFor="nomeItem">Titulo Entrada</label>
            <input
              type="text"
              id="nomeItem"
              value={titleEntrada}
              onChange={(e) => setTitleEntrada(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="valorItem">Valor</label>
            <input
              type="number"
              step="0.01"
              id="valorItem"
              ref={valorItemEntrada}
              required
            />
          </div>
          <div>
            <label htmlFor="dataItem">Data</label>
            <input
              type="date"
              id="dataItem"
              value={dataEntrada}
              onChange={(e) => setDataEntrada(e.target.value)}
              required
            />
          </div>
          <div>
            <button className="btn">Adicionar</button>
          </div>
        </form>
        {entradaMsg && <div className="alertMsg">{entradaMsg}</div>}
        {delEntrada ? (
          ""
        ) : (
          <button
            onClick={(e) => setDelEntrada(!delEntrada)}
            className="btn-excluir"
          >
            Apagar Entrada
          </button>
        )}
        {delEntrada && (
          <section className={styles.delGastos}>
            <p>Selecione o item a ser excluído</p>
            <div>
              <ul className={styles.itemHeader} style={{ margin: "0" }}>
                <li>ID/OS</li>
                <li>Nome - Item</li>
                <li>Valor</li>
                <li>Data</li>
                <li></li>
              </ul>
              {dataEntradas &&
                dataEntradas.map((item) => (
                  <ul className={styles.itemList} key={item.id}>
                    <li>{item.id}</li>
                    <li>{item.item}</li>
                    <li>R${item.total}</li>
                    <li>{item.dia}</li>
                    <li>
                      <Link to={`/deleteos/${item.id}`}>
                        <button className="btn-excluir">
                          <i className="fa fa-trash"></i>
                        </button>
                      </Link>
                    </li>
                  </ul>
                ))}
            </div>
            <div>
              <button
                className="btn"
                onClick={(e) => setDelEntrada(!delEntrada)}
              >
                Ocultar
              </button>
            </div>
          </section>
        )}
      </div>

      <div className={styles.dataContainer}>
        <div className={styles.dataTitle}>
          <h1 className="text-lightblue">Resultados</h1>
          <div className={styles.dataFilters}>
            <button onClick={(e) => setFilter("")}>Tudo</button>
            <button onClick={(e) => setFilter("entrada")}>Entradas</button>
            <button onClick={(e) => setFilter("saida")}>Saidas</button>
            <button onClick={(e) => setPrejuizo(!prejuizo)}>Adicionar</button>
          </div>
        </div>
        <p className={styles.searchBread}>
          Consulta para <span>{dataIni}</span> à <span>{dataFim}</span>
        </p>
        <ul className={styles.itemHeader}>
          <li>ID/OS</li>
          <li>Nome - Item</li>
          <li>Valor</li>
          <li>Tipo</li>
          <li>Data</li>
        </ul>
        {filteredItems &&
          filteredItems.map((item) => (
            <ul className={styles.itemList} key={Math.random()}>
              <li>{item.id}</li>
              <li>
                {item.nome} {item.os && `(${item.os})`}
              </li>
              <li>R${item.total}</li>
              <li style={{ textTransform: "capitalize" }}>{item.tipo}</li>
              <li>{item.dia}</li>
            </ul>
          ))}
        {loading && <Loader />}
      </div>
    </section>
  );
};

export default Financeiro;

import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../Components/Api";

const DeleteOs = () => {
  const { id } = useParams();
  const history = useNavigate();
  React.useState(() => {
    try {
      Axios.delete(`${API_URL}/deleteos/${id}`).then((res) => {
        history("/ordens");
      });
    } catch (err) {
      console.log(err);
      history("/ordens");
    }
  }, []);
  return <div></div>;
};

export default DeleteOs;

import React from "react";
import { API_URL } from "../Api";
import Charts from "../charts/Charts";
import Axios from "axios";
import "./Main.css";

const Main = () => {
  const lucro = 200;
  const saida = 200;
  const [resumo, setResumo] = React.useState("");
  const [pendentes, setPendetes] = React.useState(0);
  const [totalLuc, setTotalLuc] = React.useState(0);

  React.useEffect(() => {
    const d = new Date();
    const meses = d.getMonth() + 1;
    const anos = d.getFullYear();

    try {
      Axios.get(`${API_URL}/resumo/${meses}/${anos}`).then((res) => {
        setResumo(res.data[0]);
        let pen = res.data[0].totalOrdens - res.data[0].concluidas;
        let luc = res.data[0].totalGanho - res.data[0].totalGastos;
        setPendetes(pen);
        setTotalLuc(luc);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <main className="main__container">
      <div className="main__title">
        <div className="main__greeting">
          <h1>Sejá bem vindo!</h1>
          <p>Ao painel de administador</p>
        </div>
      </div>
      <div className="main__cards">
        {/* Abertura do Card */}
        <div className="card">
          <i className="fa fa-file-text fa-2x text-lightblue"></i>
          <div className="card_inner">
            <p className="text-primary-p">Número de Ordens</p>
            <span className="font-bold text-title">{resumo.totalOrdens}</span>
          </div>
        </div>
        {/* Fechamento do Card */}
        {/* Abertura do Card */}
        <div className="card">
          <i className="fa fa-check-square fa-2x text-green"></i>
          <div className="card_inner">
            <p className="text-primary-p">Ordens Concluídas</p>
            <span className="font-bold text-title">{resumo.concluidas}</span>
          </div>
        </div>
        {/* Fechamento do Card */}
        {/* Abertura do Card */}
        <div className="card">
          <i className="fa fa-archive fa-2x text-yellow"></i>
          <div className="card_inner">
            <p className="text-primary-p">Pendentes</p>
            <span className="font-bold text-title">{pendentes}</span>
          </div>
        </div>
        {/* Fechamento do Card */}
        {/* Abertura do Card */}
        <div className="card">
          <i className="fa fa-money fa-2x text-green"></i>
          <div className="card_inner">
            <p className="text-primary-p">Entradas</p>
            <span className="font-bold text-title">
              R${resumo ? resumo.totalGanho : 0}
            </span>
          </div>
        </div>
        {/* Fechamento do Card */}
      </div>

      <div className="charts">
        <div className="charts__left">
          <div className="charts__left__title">
            <div>
              <h1>Resumo do mês</h1>
              <p>Visão Geral.</p>
            </div>
            <i className="fa fa-usd"></i>
          </div>
          <Charts
            lucro={resumo ? totalLuc : 0}
            saida={resumo ? resumo.totalGastos : 0}
          />
        </div>

        <div className="charts__right">
          <div className="charts__right__title">
            <div>
              <h1>Resumo do mês</h1>
              <p>Entrada e Saida.</p>
            </div>
            <i className="fa fa-area-chart"></i>
          </div>

          <div className="charts__right_cards">
            <div className="card1">
              <h1>R${resumo ? resumo.totalGanho : 0}</h1>
              <p>Entrada</p>
            </div>

            <div className="card2">
              <h1>R${resumo ? resumo.totalGastos : 0}</h1>
              <p>Saida</p>
            </div>

            <div className="card3">
              <h1>R${resumo ? totalLuc : 0}</h1>
              <p>Lucro</p>
            </div>

            <div className="card4">
              <h1>{resumo ? pendentes : 0}</h1>
              <p>Pedente</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;

import React from "react";
import styles from "./NovoCliente.module.css";
import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../../Components/Api";

const NovoCliente = () => {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const history = useNavigate();
  const [cpf, setCpf] = React.useState(true);
  function handleCliente() {
    setCpf(!cpf);
  }

  const nome = React.useRef();
  const sobrenome = React.useRef();
  const usercpf = React.useRef();
  const usercnpj = React.useRef();
  const email = React.useRef();
  const celular = React.useRef();
  const celularop = React.useRef();
  const endereco = React.useRef();
  const numero = React.useRef();
  const complemento = React.useRef();
  const cidade = React.useRef();
  const cep = React.useRef();
  const estado = React.useRef();

  function saveUser(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (cpf) {
        var nomeClient = `${nome.current.value} ${sobrenome.current.value}`;
        var cpforcnpj = usercpf.current.value;
      } else {
        var nomeClient = nome.current.value;
        var cpforcnpj = usercnpj.current.value;
      }
      Axios.post(`${API_URL}/newclient`, {
        nome: nomeClient,
        cpf: cpforcnpj,
        email: email.current.value,
        celular: celular.current.value,
        celularop: celularop.current.value,
        endereco: endereco.current.value,
        numero: numero.current.value,
        complemento: complemento.current.value,
        cidade: cidade.current.value,
        cep: cep.current.value,
        estado: estado.current.value,
      }).then((res) => {
        const resposta = Number(
          JSON.parse(JSON.stringify(res.headers).replace("-", "")).contentlength
        );
        if (resposta === 0) {
          setErrorMsg("Falha ao cadastrar cliente, CPF já existe na base.");
          setLoading(false);
        } else {
          setLoading(false);
          history("/clientes");
        }
      });
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div className={`${styles.mainContainer}`}>
      <h1>Adicionar Cliente</h1>
      <p>Por gentileza, selecione o tipo de cliente.</p>
      <section className={styles.mainSection}>
        <div>
          <div className={styles.tipoCliente}>
            <button
              onClick={handleCliente}
              className={`${cpf ? styles.activated : ""}`}
            >
              <i className="fa fa-user" aria-hidden="true"></i>
              CPF
            </button>
            <button
              onClick={handleCliente}
              className={`${cpf ? "" : styles.activated}`}
            >
              <i className="fa fa-university" aria-hidden="true"></i>
              CNPJ
            </button>
          </div>
          {errorMsg && <div className="errorMsg">{errorMsg}</div>}
          <div className={styles.form}>
            <form action="" onSubmit={saveUser}>
              <h1 className={styles.title}>
                <i className="fa fa-address-book" aria-hidden="true"></i> Dados
                Principais
              </h1>
              {cpf ? (
                <div>
                  <input type="text" placeholder="Nome" required ref={nome} />
                  <input
                    type="text"
                    placeholder="Sobrenome"
                    required
                    ref={sobrenome}
                  />
                </div>
              ) : (
                <div className={styles.one}>
                  <input
                    type="text"
                    placeholder="Razão social"
                    required
                    ref={nome}
                  />
                </div>
              )}
              {cpf ? (
                <InputMask
                  mask="999.999.999-99"
                  placeholder="CPF"
                  ref={usercpf}
                />
              ) : (
                <InputMask
                  mask="99.999.999/9999-99"
                  placeholder="CNPJ"
                  ref={usercnpj}
                />
              )}
              <div>
                <InputMask
                  mask="(99) 9 9999-9999"
                  placeholder="Celular"
                  required
                  ref={celular}
                />
                <InputMask
                  mask="(99) 9999-9999"
                  placeholder="Telefone - Opcional"
                  ref={celularop}
                />
              </div>
              <div className={styles.one}>
                <input
                  type="email"
                  placeholder="Email ex: admin@admin.com.br"
                  ref={email}
                />
              </div>
              <h1 className={styles.title}>
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                Endereço
              </h1>
              <div>
                <input
                  type="text"
                  placeholder="Endereço ex: Rua Avenida Capitão Francisco Ignácio"
                  ref={endereco}
                />
                <input type="number" placeholder="Numero" ref={numero} />
                <input
                  type="text"
                  placeholder="Complemento"
                  ref={complemento}
                />
                <input type="text" placeholder="Cidade" ref={cidade} />
                <input type="text" placeholder="Estado" ref={estado} />
                <InputMask mask="99999-999" placeholder="Cep" ref={cep} />
              </div>
              <div className={styles.buttonDiv}>
                <Link to="/clientes">
                  <button className={styles.cancel}>Cancelar</button>
                </Link>
                <button className={styles.next}>Cadastrar</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NovoCliente;

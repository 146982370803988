import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Login from "./Components/Login";
import Main from "./Components/main/Main";
import NavBar from "./Components/NavBar/NavBar";
import SideNav from "./Components/SideNav/SideNav";
import { AuthProvider } from "./contexts/AuthContext";
import Clientes from "./Pages/Clientes/Clientes";
import Delete from "./Pages/Delete";
import DeleteGastos from "./Pages/DeleteGastos";
import DeleteOs from "./Pages/DeleteOs";
import EditarCliente from "./Pages/EditarCliente/EditarCliente";
import EditarOs from "./Pages/EditarOs/EditarOs";
import Financeiro from "./Pages/Financeiro/Financeiro";
import Gastos from "./Pages/Gastos/Gastos";
import Gerar from "./Pages/Gerar/Gerar";
import NovaOS from "./Pages/NovaOS/NovaOS";
import NovoCliente from "./Pages/NovoCliente/NovoCliente";
import Ordens from "./Pages/Ordens/Ordens";
import VerOs from "./Pages/VerOs/VerOs";
import PrivateRoute from "./Components/PrivateRoute";
import Signup from "./Components/Signup";
import Error404 from "./Pages/Error404";
import FinanceiroNew from "./Pages/FinanceiroNew/FinanceiroNew";

function App() {
  const [sidebar, setSidebar] = React.useState(false);
  const openSideBar = () => {
    setSidebar(true);
  };
  const closeSideBar = () => {
    setSidebar(false);
  };

  return (
    <div className="container">
      <BrowserRouter>
        <AuthProvider>
          <SideNav
            sidebar={sidebar}
            openSideBar={openSideBar}
            closeSideBar={closeSideBar}
            setSidebar={setSidebar}
          />
          <NavBar
            sidebar={sidebar}
            openSideBar={openSideBar}
            closeSideBar={closeSideBar}
          />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <PrivateRoute>
                  <Main />
                </PrivateRoute>
              }
            />
            <Route
              path="/clientes"
              exact
              element={
                <PrivateRoute>
                  <Clientes />
                </PrivateRoute>
              }
            />
            <Route
              path="/financeiro/*"
              exact
              element={
                <PrivateRoute>
                  <Financeiro />
                </PrivateRoute>
              }
            />
            <Route
              path="/financeiro-beta/*"
              exact
              element={
                <PrivateRoute>
                  <FinanceiroNew />
                </PrivateRoute>
              }
            />
            <Route
              path="/novaos"
              exact
              element={
                <PrivateRoute>
                  <NovaOS />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerar/:id"
              exact
              element={
                <PrivateRoute>
                  <Gerar />
                </PrivateRoute>
              }
            />
            <Route
              path="/delete/:id"
              exact
              element={
                <PrivateRoute>
                  <Delete />
                </PrivateRoute>
              }
            />
            <Route
              path="/deleteos/:id"
              exact
              element={
                <PrivateRoute>
                  <DeleteOs />
                </PrivateRoute>
              }
            />
            <Route
              path="/deletegastos/:id"
              exact
              element={
                <PrivateRoute>
                  <DeleteGastos />
                </PrivateRoute>
              }
            />
            <Route
              path="/gastos/:id"
              exact
              element={
                <PrivateRoute>
                  <Gastos />
                </PrivateRoute>
              }
            />
            <Route
              path="/novocliente"
              element={
                <PrivateRoute>
                  <NovoCliente />
                </PrivateRoute>
              }
            />
            <Route
              path="/ordens"
              element={
                <PrivateRoute>
                  <Ordens />
                </PrivateRoute>
              }
            />
            <Route
              path="/veros/:id"
              element={
                <PrivateRoute>
                  <VerOs />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit/:id"
              element={
                <PrivateRoute>
                  <EditarCliente />
                </PrivateRoute>
              }
            />
            <Route
              path="/editos/:id"
              element={
                <PrivateRoute>
                  <EditarOs />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Error404 />} />
            {/* <Route path="/signup" element={<Signup />} /> */}
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import styles from "./Ordens.module.css";
import Axios, { all } from "axios";
import { API_URL } from "../../Components/Api";
import { Link } from "react-router-dom";
import Loader from "../../Components/Helper/Loader";

const Ordens = () => {
  const [clienteData, setClienteData] = React.useState("");
  const [allData, setAllData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [filterOption, setFilterOption] = React.useState(10);
  const [query, setQuery] = React.useState("");
  const [arrayItem, setArrayItem] = React.useState("");
  const [showMore, setShowMore] = React.useState(false);
  const [contador, setContador] = React.useState(0);
  const [concluidas, setConcluidas] = React.useState(0);
  const [pendentes, setPendentes] = React.useState(0);

  //Concluidas // Pendentes
  React.useEffect(() => {
    if (allData) {
      let contadorC = 0;
      let contadorP = 0;
      allData.forEach((e) => {
        if (e.statusOrdem === "Concluida" && allData) {
          contadorC++;
        } else {
          contadorP++;
        }
      });
      setConcluidas(contadorC);
      setPendentes(contadorP);
    }
  }, [allData]);

  // Query Api Data
  React.useEffect(() => {
    try {
      setLoading(true);
      Axios.get(`${API_URL}/ordens`).then((res) => {
        setClienteData(res.data.slice(0, filterOption));
        setAllData(res.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [filterOption]);

  // Show More Button && Cliente Mês
  React.useEffect(() => {
    if (clienteData.length === 0) {
      setShowMore(false);
    } else if (clienteData.length < allData.length) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }

    let d = new Date();
    let mesAtual = d.getMonth() + 1;
    let numero = 0;
    if (allData) {
      allData.forEach((e) => {
        if (e.dia.split("-")[1] == mesAtual) {
          numero++;
          setContador(numero);
        }
      });
    }
  }, [clienteData]);

  // Serach Filter
  React.useEffect(() => {
    let novaArray = [];
    if (query === "") {
      setArrayItem("");
      setClienteData(allData.slice(0, filterOption));
      setFilterOption(10);
    } else if (allData) {
      allData.filter((item) => {
        if (
          item.nome.toLowerCase().includes(query.toLocaleLowerCase()) ||
          item.cpf
            .toLowerCase()
            .replaceAll(".", "")
            .replaceAll("-", "")
            .replaceAll("/", "")
            .includes(query.toLocaleLowerCase()) ||
          JSON.stringify(item.id).includes(query) ||
          item.statusOrdem.toLowerCase().includes(query.toLowerCase())
        ) {
          novaArray.push(item);
        }
        setArrayItem(novaArray);
        setClienteData("");
      });
    }
  }, [query]);

  //Show More increase
  function handleMore(e) {
    e.preventDefault();
    setFilterOption(filterOption + 10);
  }

  return (
    <section className={styles.mainContainer}>
      <div className={styles.greetings}>
        <h1>Ordens de serviços</h1>
        <p className="text-primary-p">Status da base atual.</p>
      </div>
      <div className={styles.topCards}>
        <div>
          <i className="fa fa-2x fa-list-alt"></i>
          <p className="text-primary-p">Número de ordens</p>
          <span className="font-bold text-title">
            {allData ? allData.length : "Carregando..."}
          </span>
        </div>
        <div>
          <i className="fa fa-2x fa-trophy"></i>
          <p className="text-primary-p">Ordens esse Mês</p>
          <span className="font-bold text-title">
            {contador ? contador : "Nenhum"}
          </span>
        </div>
        <div>
          <i className="fa fa-2x fa-check-square"></i>
          <p className="text-primary-p">Ordens concluidas</p>
          <span className="font-bold text-title">{concluidas}</span>
        </div>
        <div>
          <i
            className="fa fa-2x fa-exclamation-triangle"
            style={{ color: "#b10000" }}
          ></i>
          <p className="text-primary-p">Ordens Pendentes</p>
          <span className="font-bold text-title">{pendentes}</span>
        </div>
      </div>

      <div className={styles.clientList}>
        <div className={styles.search}>
          <h1 className="text-lightblue">Ordens Emitidas</h1>
          <div className={styles.searchBar}>
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                placeholder="Pesquisar Cliente"
              />
              <button type="button">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
        <div>
          <ul className={styles.headerList}>
            <li>O.S.</li>
            <li>Nome</li>
            <li>Cpf</li>
            <li>Email</li>
            <li>Status</li>
          </ul>
          <div className={styles.clients}>
            {arrayItem &&
              arrayItem.map((cliente) => (
                <Link to={`/veros/${cliente.id}`}>
                  <ul className={styles.clientData}>
                    <li>
                      <b>{cliente.id}</b>
                    </li>
                    <li>{cliente.nome}</li>
                    <li>{cliente.cpf}</li>
                    <li>{cliente.email}</li>
                    <li>{cliente.statusOrdem}</li>
                  </ul>
                </Link>
              ))}
            {clienteData &&
              clienteData.map((cliente) => (
                <Link to={`/veros/${cliente.id}`}>
                  <ul className={styles.clientData}>
                    <li>
                      <b>{cliente.id}</b>
                    </li>
                    <li>{cliente.nome}</li>
                    <li>{cliente.cpf}</li>
                    <li>{cliente.email}</li>
                    <li>{cliente.statusOrdem}</li>
                  </ul>
                </Link>
              ))}
          </div>
          {loading && <Loader />}
          {showMore && (
            <div className={styles.showMore}>
              <button className="btn" onClick={handleMore}>
                Mostrar mais
              </button>
            </div>
          )}
          <div className={styles.serachRes}>
            Mostrando {clienteData && clienteData.length}
            {arrayItem && arrayItem.length} resultados de{" "}
            {allData && allData.length} Clientes.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ordens;
